import { Helmet } from 'react-helmet';

const useSchemaOrg = (customData) => {
  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Trasmontano Saúde",
    "url": "https://trasmontanosaude.com/",
    "logo": "https://trasmontanosaude.com/logo.png",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+55 13 98126-0507",
      "contactType": "sales",
      "areaServed": "BR",
      "availableLanguage": ["Portuguese"],
    },
    ...customData,
  };

  // Retornar o JSX diretamente
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schemaData)}
      </script>
    </Helmet>
  );
};

export default useSchemaOrg;
