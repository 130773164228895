import './Home.css';
import { Helmet } from 'react-helmet';

import Carousel from './carousel/Carousel.js';
import BenefitsCarousel from './benefits/Benefits.js';
import usePageTracking from '../../../hooks/usePageTracking.js';
import useSchemaOrg from '../../../hooks/SchemaOrg.js';

const Home = () => {

    const schemaData = {
        "description": "Trasmontano Saúde, referência em atendimento humanizado e serviços médicos de excelência. Contrate online e garanta saúde e bem-estar com facilidade e confiança, além de uma ampla cobertura.",
        "url": "https://trasmontanosaude.com/",
        }
    ;


    useSchemaOrg(schemaData);
    usePageTracking();

    return (
        <div className='home'>

            <Helmet>
                <title>Trasmontano Saúde | Planos de Saúde</title>
                <meta name="description"
                    content="Trasmontano, referência em atendimento humanizado e serviços médicos de excelência. Contrate online e garanta saúde e bem-estar com facilidade e confiança, além de uma ampla cobertura." />
            </Helmet>


            <Carousel />
            <BenefitsCarousel />

        </div>
    )
}

export default Home;