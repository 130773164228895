import { Helmet } from 'react-helmet';

import './About.css';

import AboutInfo from './aboutinfo/AboutInfo.js';
import Abrangencia from './abrangencia/Abrangencia.js';
import Mission from './mission/Mission.js';
import Cobertura from './cobertura/Cobertura.js';
import usePageTracking from '../../../hooks/usePageTracking.js';

import family from '../../../assets/img-main/family.png';

import timeline from '../../../assets/img-main/time-line.png';
import useSchemaOrg from '../../../hooks/SchemaOrg.js';

const About = () => {

    const schemaData = {
        "description": "Saiba mais sobre a Trasmontano Saúde, a empresa que oferece planos de saúde acessíveis e de qualidade. Nossa missão é proporcionar saúde e bem-estar para você e sua família.",
        "url": "https://trasmontanosaude.com/sobre",
    };

    useSchemaOrg(schemaData);
    usePageTracking();

    return (
        <div className="about__container">

            <Helmet>
                <title>Sobre Nós | Trasmontano Saúde</title>
                <meta name="description"
                    content="Saiba mais sobre a Trasmontano Saúde, a empresa que oferece planos de saúde acessíveis e de qualidade. Nossa missão é proporcionar saúde e bem-estar para você e sua família." />
            </Helmet>


            <section className='about__banner-container'>

                <div className='about__banner'>
                    <img
                        className='banner__img'
                        src={family}
                        alt="Imagem de uma família"
                    />
                </div>

                <AboutInfo />

            </section>

            <div className='time-line'>
                    <img
                        src={timeline}
                        alt='Time Line da Trasmontano'
                    />
                </div>

            <section className="additional__info">
                <Mission />
            </section>

            <section className="abrangencia">
                <Abrangencia />
            </section>

            <section className="cobertura">
                <Cobertura />
            </section>
        </div>

    )
}

export default About;